import { combineReducers } from 'redux';

// reducer import
import qrcodeReducer from './modalQRCodeReducer';
import alertReducer from '../oauth2/reducers/alertReducer.js';
import authReducer from '../oauth2/reducers/authReducer.js';
// import themeReducer from '../oauth2/reducers/themeReducer.js';


// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  qrcode: qrcodeReducer,
  auth: authReducer, 
  alert: alertReducer, 
});

export default reducer;
