import { lazy } from 'react';

// project imports
import TakyonLayout from '../layout/TakyonLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const MarketingPage = Loadable(lazy(() => import('../marketing-page/MarketingPage')));
const Blog = Loadable(lazy(() => import('../blog/Blog')));

// ==============================|| MAIN ROUTING ||============================== //

const TakyonRoutes = {
  path: '/',
  element: <TakyonLayout />,
  children: [
    {
      path: '/',
      element: <MarketingPage />
    }
  ]
};

export default TakyonRoutes;
