import { lazy } from 'react';

// project imports
import TakyonLayout from '../layout/TakyonLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const MobileMarketingPage = Loadable(lazy(() => import('../marketing-page/mobile/MobileMarketingPage')));

// ==============================|| MAIN ROUTING ||============================== //

const MobileBuybakRoutes = {
  path: '/',
  element: <TakyonLayout />,
  children: [
    {
      path: '/',
      element: <MobileMarketingPage />
    },
  ]
};

export default MobileBuybakRoutes;
